"use strict";
var scrollbarWidth = require('./scrollbarWidth');

module.exports = function () {

    // Popup appearance
    window.popupShow = function(popup) {
        var $popup = $(popup),
            $overlay = $('.overlay');

        if ($('.popup').is('.popup_visible')) {
            $('.popup_visible').hide();
        } else {
            $('body').
                css('margin-right', scrollbarWidth()).
                addClass('overlayed');
            $overlay.show(0, function(){
                $overlay.addClass('overlay_visible');
            });
        }

        $popup.show(0, function(){
            popupPos($popup);
            $popup.addClass('popup_visible');
        });

        return false;
    }

    // Popup positioning
    function popupPos(hero) {
        var $hero = hero,
            verticalOffset = 30;

        if ($hero.outerHeight() + verticalOffset*2 > $(window).height()) {
            $hero.css({
                'top': verticalOffset,
                'margin-top': 0
            });           
        } else {
            $hero.css({
                'top': '50%',
                'margin-top': -$hero.outerHeight()/2
            });
        }
    }

    // Popup closing
    function popupClose() {
        $('.popup').removeClass('popup_visible');
        $('.overlay').removeClass('overlay_visible');
        setTimeout(function(){
            $('.popup').hide();
            $('.overlay').hide();
            $('body').
                css('margin-right', 0).
                removeClass('overlayed');
        }, 300);
    }

    // Popup init
    $('[data-popup]').on('click', function(){
        popupShow('#' + $(this).data('popup'));
    });

    // Popup positioning after resizing window
    $(window).on('resize', function(){
        if ($('.popup:visible').length) {
            var $popup = $('.popup:visible');

            popupPos($popup);
        }
    });

    // Popup closing events
    $('[data-popup-close]').on('click', function(){
        popupClose();
        return false;
    });

    $('.overlay').on('click', function(e){
        if(!$(e.target).parents('.overlay').length){
            popupClose();
        }
    });

    $(document).keyup(function(e) {
        if (e.keyCode == 27 && $('.popup').is('.popup_visible')) {
            popupClose();
        }
    });

};