"use strict";

module.exports = function () {
	
	$('.promo__navItem').on('mouseenter', function(){
		var index = $(this).index(),
			$content = $($('.promo__content').find('.promo__item')[index]);

		$(this).siblings('.promo__navItem_active').removeClass('promo__navItem_active');
		$(this).addClass('promo__navItem_active');
		$content.siblings('.promo__item_active').removeClass('promo__item_active');
		$content.addClass('promo__item_active');
		
	});

};