"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {
	
	$('.brands__slider')
		.after('<div class="brands__prev" /><div class="brands__next" />')
		.swiper({
			slidesPerView: 'auto',
			loopedSlides: 5,
			loop: true,
			simulateTouch: false,
			prevButton: '.brands__prev',
			nextButton: '.brands__next'
		});

};