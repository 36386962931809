"use strict";
require("./../../../bower_components/jquery-ui/ui/core");
require("./../../../bower_components/jquery-ui/ui/widget");
require("./../../../bower_components/jquery-ui/ui/button");
require("./../../../bower_components/jquery-ui/ui/spinner");

module.exports = function () {
	
    $('.spinner').spinner({
    	min: 1,
		create: function(event, ui) {
			$('.ui-spinner-down').html('');
			$('.ui-spinner-up').html('');
		}
	});

};