"use strict";

module.exports = function () {
	
	$('.nav__link-sm').on('click', function(e){
		var $holder = $(this).parent('.nav__item');

		if ($holder.is('.nav__item_open')) {
			$holder.removeClass('nav__item_open');
		} else {
			$('.nav__item_open').removeClass('nav__item_open');
			$holder.addClass('nav__item_open');
		}
		e.preventDefault();
	});

	$(document).on('click', function(e){
		if (!$(e.target).parents('.nav__list').length) {
			$('.nav__item_open').removeClass('nav__item_open');
		}
	});

};