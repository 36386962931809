"use strict";
var $ = window.jQuery = window.$ = require("./../../bower_components/jquery/dist/jquery.js");


var initCustomWidgets = function () {
	require('./modules/navInit')();
	require('./modules/brandsInit')();
	require('./modules/promoInit')();
	require('./modules/bannersInit')();
	require('./modules/timeerrorInit')();
	require('./modules/magnificInit')();
	require('./modules/tabsInit')();
	require('./modules/spinnerInit')();
	require('./modules/popupInit')();
	require('./modules/navToggleInit')();
};

$(initCustomWidgets);
