"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {
	
	$('.banners')
		.append('<div class="banners__pagination" />')
		.swiper({
			slidesPerView: 1,
			effect: 'fade',
			speed: 500,
			autoplay: 5000,
			loop: true,
			simulateTouch: false,
			pagination: '.banners__pagination',
			paginationClickable: true,
			bulletClass: 'banners__bullet',
			bulletActiveClass: 'banners__bullet_active'
		});

};